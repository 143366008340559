div.react-datepicker-popper {
    z-index: 2;
}

/*
 * Add scroll bar to select options
 * cf. https://github.com/ionic-team/ionic/issues/17397#issuecomment-496092852
 */
.action-sheet-group::-webkit-scrollbar,
.alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar {
    width: 1em;
    display: block !important;
}

.action-sheet-group::-webkit-scrollbar-track,
.alert-radio-group::-webkit-scrollbar-track,
.alert-checkbox-group::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.action-sheet-group::-webkit-scrollbar-thumb,
.alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.alert-radio-group {
    min-height: 40vh;
}

.bigIcon {
    font-size: 2em;
}

.infIcon {
    padding-right: 0.5em;
}

ion-avatar { 
    width: 7vw;  
    height: 7vw;
    min-width: 100px;  
    min-height: 100px;  
}

ion-progress-bar {
    margin-top: 0.5em;
}

.eventPhoto {
    max-height: 20vh;
}

h5 {
    margin: 0 !important;
    padding: 0.3em;
}

.capacityPercentage {
    min-width: 60px;
}
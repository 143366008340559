.resultbox {
    padding: 2em;
    border-radius: 0.5em;
    &.fail {
        background-color: var(--ion-color-danger);
        color: var(--ion-color-danger-contrast);
    }
    
    &.success {
        background-color: var(--ion-color-success);
        color: var(--ion-color-success-contrast);
    }
}

.rotate-scale-up {
    max-width: 10vw;
	-webkit-animation: rotate-scale-up 1.2s linear 0.5s infinite both;
	        animation: rotate-scale-up 1.2s linear 0.5s infinite both;
}
/* ----------------------------------------------
 * Generated by Animista on 2020-6-7 16:14:1
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-scale-up
 * ----------------------------------------
 */
 @-webkit-keyframes rotate-scale-up {
    0% {
      -webkit-transform: scale(1) rotateZ(0);
              transform: scale(1) rotateZ(0);
    }
    50% {
      -webkit-transform: scale(2) rotateZ(180deg);
              transform: scale(2) rotateZ(180deg);
    }
    100% {
      -webkit-transform: scale(1) rotateZ(360deg);
              transform: scale(1) rotateZ(360deg);
    }
  }
  @keyframes rotate-scale-up {
    0% {
      -webkit-transform: scale(1) rotateZ(0);
              transform: scale(1) rotateZ(0);
    }
    50% {
      -webkit-transform: scale(2) rotateZ(180deg);
              transform: scale(2) rotateZ(180deg);
    }
    100% {
      -webkit-transform: scale(1) rotateZ(360deg);
              transform: scale(1) rotateZ(360deg);
    }
  }
  
ion-list {
    width: 100%;
}

.capacity {
    margin-left: 1em;
    min-width: 7em;
}

.dayGrid {
    -webkit-box-shadow: 5px 5px 10px -6px rgba(0,0,0,0.37);
    -moz-box-shadow: 5px 5px 10px -6px rgba(0,0,0,0.37);
    box-shadow: 5px 5px 10px -6px rgba(0,0,0,0.37);
}